<template>
  <div class="character-profile-editor-add-section position-relative mb-4">
    <div class="select-like" @click="openSectionTypesModal">
      <div class="d-flex justify-content-between align-items-center h-100">
        <div></div>
        <div class="title d-flex align-items-center">
          <i class="ti-plus icon mr-1" />
          Add Section
        </div>
        <ion-button class="arrow-btn">
          <i class="ti-angle-down icon" />
        </ion-button>
      </div>
    </div>
    <FeaturePreviewModal
      :isOpen="!!featurePreviewModalIsOpen"
      :previewKey="featurePreviewModalKey"
      :previewName="featurePreviewModalFeatureName"
      @close="closeFeaturePreviewModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { possibleSectionsLabelMap, unlockableSectionsLabelMap } from '@/shared/statics/new-character-profile';
import { authStore } from '@/shared/pinia-store/auth';
import { featureFlags } from '@/shared/config/feature-flags';
import { modalController } from '@ionic/vue';
import { openSubscribeModal } from '@/shared/utils/modals';
import EditorSectionTypes from '@/shared/modals/EditorSectionTypes.vue';
import FeaturePreviewModal from '@/shared/modals/FeaturePreviewModal.vue';

const { addSection, character } = characterEditorStore();
const featurePreviewModalKey = ref('');
const featurePreviewModalFeatureName = ref('');
const featurePreviewModalIsOpen = ref(false);

const openFeaturePreviewModal = (key: any, value: any) => {
  featurePreviewModalIsOpen.value = true;
  featurePreviewModalKey.value = key;
  featurePreviewModalFeatureName.value = value;
};

const closeFeaturePreviewModal = (actionKey?: string) => {
  featurePreviewModalIsOpen.value = false;
  setTimeout(() => {
    featurePreviewModalKey.value = '';
    featurePreviewModalFeatureName.value = '';
  }, 100);
  if (actionKey === 'openSubscribeModal') {
    openSubscribeModal(null, 'characterAddSection');
  }
};

const hasValue = (_: string, value: any) => {
  return value || value === '' || value === 0;
};

const unlockableSections = computed(() => {
  const { subscriptionLevel } = authStore();
  if (!featureFlags.pro) return {};
  if (subscriptionLevel.value) {
    return {};
  }
  return unlockableSectionsLabelMap;
}) as any;

const fullPossibleSectionsLabelMap = computed(() => {
  const { userPro } = authStore();
  const existingKeys = character?.value?.info?.order.filter((key: string) =>
    hasValue(key, character?.value?.info[key])
  );
  const possibleSectionsCloned = { ...possibleSectionsLabelMap };
  if (!get(userPro.value, 'feature_alignmentcharts')) delete possibleSectionsCloned.alignment_charts;
  if (!get(userPro.value, 'feature_timeline')) delete possibleSectionsCloned.timeline;
  if (!get(userPro.value, 'attributesliders')) delete possibleSectionsCloned.qualities;
  if (!get(userPro.value, 'feature_trivia')) delete possibleSectionsCloned.trivia;

  const nonRepeatedPossibleSectionsLabelMap = Object.keys(possibleSectionsCloned).reduce((acc, curr) => {
    if (existingKeys?.includes(curr)) return acc;
    return {
      ...acc,
      [curr]: possibleSectionsCloned[curr],
    };
  }, {});

  return {
    text: 'Custom Section',
    moreAbout: 'More About',
    ...nonRepeatedPossibleSectionsLabelMap,
  };
}) as any;

const openSectionTypesModal = async () => {
  const modal = await modalController.create({
    component: EditorSectionTypes,
    componentProps: {
      sections: fullPossibleSectionsLabelMap.value,
      unlockableSections: unlockableSections.value,
    },
    cssClass: `modal-cmfl options-modal`,
  });

  modal.componentProps = { ...modal.componentProps, instance: modal };

  modal.onDidDismiss().then((value) => {
    // value.data === key
    if (!value?.data) return;
    const { userPro } = authStore();
    if (
      (!get(userPro.value, 'feature_alignmentcharts') && value?.data === 'alignment_charts') ||
      (!get(userPro.value, 'attributesliders') && value?.data === 'qualities') ||
      (!get(userPro.value, 'feature_timeline') && value?.data === 'timeline') ||
      (!get(userPro.value, 'feature_trivia') && value?.data === 'trivia')
    ) {
      openFeaturePreviewModal(value?.data, unlockableSections.value[value.data as string]);
      return;
    }
    addSection(`${value?.data}`);
  });

  await modal.present();
};
</script>

<style lang="sass" scoped>
.pro-tag
  width: 30px
  height: 15px
  border-radius: 8px
  color: #FFF
  background: var(--ion-color-primary)
  font-size: 10px
.character-profile-editor-add-section
  .select-like
    cursor: pointer
    background: #fff
    border: 3px solid #214163
    height: 50px
    border-radius: 12px
    .title
      font-weight: bold
      font-size: 18px
    .icon
      font-weight: bolder
      font-size: 16px
    .arrow-btn
      height: 46px
      margin-right: -1px
      border-left-style: solid
      border-left-color: #214163
      border-left-width: 3px
      border-radius: 11px
      border-top-width: 3px
      border-bottom-left-radius: 11px
      --border-radius: 9px 9px 10px 9px
      width: 49px
      border-top-style: solid
      border-top-width: 0.5px
      border-top-color: #214163
      border-bottom-style: solid
      border-bottom-width: 0.5px
      border-bottom-color: #214163
      --background: linear-gradient(to bottom, rgb(217 26 216) 0%, rgb(204 34 218) 49%, rgb(179 52 227) 100%)
    &:hover
      opacity: 0.8
.dark .arrow-btn
  height: 45px !important
  border-left-color: #17074c !important
  --border-radius: 9px 9px 9px 7px !important
  border-top-color:  #17074c !important
  border-bottom-color:  #17074c !important
  border-bottom-left-radius: 9px !important
</style>
