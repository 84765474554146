<template>
  <ion-page class="page scrollable character-editor-page">
    <div>
      <Transition>
        <div v-if="character" class="pb-3">
          <div class="text-center w-100 title">{{ title }}</div>
          <Header class="mt-4" />
          <KeyInfo v-if="!isTemplate" class="one-section" :class="{ 'free-space': id }" />
          <TemplateKeyInfo v-else class="one-section" :class="{ 'free-space': id }" />
          <Sections />
          <AddSection class="one-section mt-3" />
          <Settings class="mt-5" />
          <Actions class="mt-4" @done="markIsDone" />
        </div>
        <HeaderLoading v-else-if="loading" />
      </Transition>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import HeaderLoading from './components/HeaderLoading.vue';
import TemplateKeyInfo from './components/TemplateKeyInfo.vue';
import AddSection from './components/AddSection.vue';
import Actions from './components/Actions.vue';
import Settings from './components/Settings.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
const Header = defineAsyncComponent(() => import('./components/Header.vue'));
const KeyInfo = defineAsyncComponent(() => import('./components/KeyInfo.vue'));
const Sections = defineAsyncComponent(() => import('./components/Sections.vue'));
const { character, loadEditor, isTemplate, loading, setIsDone, clearAll } = characterEditorStore();

const route = useRoute();

const id = computed(() => {
  const { id } = route.params;
  if (character.value?.id) return character.value.id;
  if (Array.isArray(id)) return '';
  return id;
});

const markIsDone = () => {
  setIsDone(true);
};

const title = computed(() => {
  const initialWord = id.value ? 'Edit' : 'Create';

  if (isTemplate.value) {
    return `${initialWord} character preset`;
  }

  return `${initialWord} your Character`;
});

function getElementScrollPosition(element: HTMLElement) {
  var rect = element.getBoundingClientRect();
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return rect.top + scrollTop;
}

const goToImages = () => {
  const router = useRouter();
  const goToImages = !!router.currentRoute.value.query.images;

  if (goToImages) {
    const imagesSection = document.querySelector('.gallery') as HTMLElement;
    const scroller = document.querySelector('.layout-scroll-area') as HTMLElement;

    if (imagesSection && scroller) {
      scroller.scrollBy({ top: getElementScrollPosition(imagesSection), behavior: 'smooth' });
    }
  }
};

onMounted(async () => {
  const routeName = route.name as string;
  const presetId = route.query.presetId as string;
  try {
    clearAll();
    await loadEditor(id.value, routeName, presetId);
    goToImages();
  } catch (e) {
    setIsDone(true); // don't trap the user if the editor failed to load
  }
});

onBeforeRouteLeave((_, __, next) => {
  const { isDone } = characterEditorStore();
  if (isDone?.value) {
    clearAll();
    return next();
  }
  if (!confirm('Are you sure you want to leave? You may lose unsaved changes.')) {
    return;
  }
  clearAll();
  next();
});
</script>

<style lang="sass" scoped>
.free-space
  margin-top: 0px !important
.character-editor-page
  padding-top: 24px !important
  padding-bottom: 24px !important
  ::v-deep
    .reorder-selected
      border-radius: 10px !important
  .title
    color: #4D4D4D
    font-size: 40px
    font-weight: bold
    margin-top: 10px
  .one-section
    margin-top: 60px

@media(max-width: 500px)
  .title
    font-size: 30px !important
</style>
