<template>
  <ion-modal
    class="modal-content-self"
    :is-open="isOpen"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @willDismiss="dismissModal"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title>Upgrade your plan</ion-title>
        <ion-buttons slot="end">
          <ion-button size="large" @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="inner-content" v-if="key">
      <h3 class="mx-2 pt-3">
        <b>{{ previewName }}</b> is a <span class="ptag"><PlusTag size="lg" /></span> feature!
      </h3>
      <h5 class="mx-2">{{ description }}</h5>
      <div class="preview-container mt-3">
        <div class="preview-content">
          <div class="preview-label">Preview:</div>
          <img :src="`/images/${key}.png`" alt="Feature preview image" />
        </div>
      </div>
    </div>
    <div class="learn-more-section">
      <ion-button class="learn-more-button" size="large" @click="dismissModal('openSubscribeModal')">
        <span class="no-select bold">Learn More</span>
      </ion-button>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import PlusTag from '@/shared/components/PlusTag.vue';
import { featurePreviewDescriptions } from '@/shared/statics/constants';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  previewKey: {
    type: String,
    required: true,
  },
  previewName: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['close']);
const isOpen = toRef(props, 'isOpen');
const key = toRef(props, 'previewKey');
const previewName = toRef(props, 'previewName');

const description = computed(() => {
  return featurePreviewDescriptions[key.value as keyof typeof featurePreviewDescriptions];
});

const dismissModal = (key?: string) => {
  emit('close', key);
};
</script>

<style lang="sass" scoped>
.learn-more-button
  margin-top: 1rem
  --border-radius: 18px !important
  --background: linear-gradient(131.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 50%, #4FBDE5 75%, #6DFFE5 110%)

.inner-content
  background: var(--ion-background-color, #fff)
  height: inherit
  overflow-y: auto
  overflow-x: hidden
  text-align: center


.preview-container
  display: flex
  flex-direction: column
  align-items: center
  text-align: center

.preview-content
  display: flex
  flex-direction: column
  align-items: flex-start
  width: fit-content

.preview-label
  align-self: flex-start

.ptag
  display: inline-block
  position: relative
  bottom: 2px

.learn-more-section
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-end
  padding: 0.7rem
  background-color: var(--ion-background-color, #fff)
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  margin-top: auto

.scrollable-content
  overflow-y: auto
  width: 100%
  padding: 1rem
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
</style>
